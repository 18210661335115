














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'LandingTitle' })
export default class LandingTitle extends Vue {
  @Prop()
  middle!: boolean

  @Prop()
  small!: boolean

  @Prop()
  bold!: boolean
}
